import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnketService {

  token: any;
  //url = 'assets/form.json';
  url = 'https://api.gungoren.bel.tr/v1/polling/survey/?token=';
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }


  sendGetRequest(token: any) {
    console.log("SENDING REQUEST: " + this.url + token);

    return this.http.get(this.url + token);
  }

  isLoading = true;
  errorPage = false;
  intro = false;
  successPage = false

  showIntro() {
    this.intro = true
  }
  hideIntro() {
    this.intro = false
  }

  showLoading() {
    this.isLoading = true;
  }
  hideLoading() {
    this.isLoading = false
  }
  showError() {
    this.errorPage = true;
  }
  hideError() {
    this.errorPage = false
  }
  showSuccess() {
    this.successPage = true;
  }
  hideSuccess() {
    this.successPage = false
  }
}
