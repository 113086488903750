import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-succes-page',
  templateUrl: './succes-page.component.html',
  styleUrls: ['./succes-page.component.css']
})
export class SuccesPageComponent implements OnInit {
  successPage=true
  constructor() { }

  ngOnInit(): void {
  }

}
