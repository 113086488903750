import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnketComponent } from './anket/anket.component';
import { ErrorPageComponent } from "./error-page/error-page.component";
import { SuccesPageComponent } from "./succes-page/succes-page.component";

const routes: Routes = [
  { path: '', component: AnketComponent },
  // { path: 'form/:id?token=', component: AnketComponent },
  // { path: 'login/:access_token', component: AnketComponent },
  { path: 'hata', component: ErrorPageComponent },
  { path: 'basarili', component: SuccesPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
