
<div *ngIf="errorPage" class="container">
  <div class="card">
    <div class="card-1">
      <div style="border-radius:200px; height:200px; width:200px; background: #ff8d7a; margin:0 auto;">
        <!-- <p class="checkmark">!</p> -->
        <i class="fas fa-exclamation checkmark text-dark"></i>
      </div>
        <h1 class="pt-5 pb-2">Hata Oluştu!</h1> 
        <hr>
        <p class="pt-2">Yanıtınız zaten gönderildi! <br/><br/> Bu form, kişi başına yalnızca bir yanıta izin veriyor.</p>
    </div>
    
    </div>
</div>
