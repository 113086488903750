import { Component, Input,OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnketService } from "../app/anketServices/anket.service";
import { field } from "../app/global.model";
import {  AnketComponent} from "../app/anket/anket.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'form-anket';
  

  @Input() token: any;
  
  field: field[]=[];
 
  constructor(private anketService:AnketService) {}

  ngOnInit() {


    // console.log(this.token)
    // this.anketService.sendGetRequest().subscribe((data: any)=>{
    //   console.log(data);
    //   this.field = data;
    //   console.log(field)
    // })  
  }
  
  // showData() {
  //   this.anketService.getData().subscribe(response => {
  //     // Write your logic here to handle the data
      
  //     console.log(response);
  //   });
  // }

}
