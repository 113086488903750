<section *ngIf="intro" class="intro">
  <div class="bg-image h-100" style="background: #daedea">
    <div class="mask d-flex align-items-center h-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-9 col-xl-7">
            <div class="card mask-custom mx-2 mt-4 mb-5">
              <div class="card-body px-2 px-md-4 px-lg-5 text-color">
                <div class="my-1">
                  <img src="../../assets/img/gungoren.svg" style="height: 80px"
                    class="rounded mx-auto d-block mb-3" alt="..." />
                  <div class="text-center">
                    <h1 class="h1">{{ formModel.name }}</h1>
                    <p class="lead pb-3">{{ formModel.description }}</p>
                  </div>
                  <form #data="ngForm" (ngSubmit)="submit(data.value)" id="anket">
                    <div *ngFor="let item of formModel.attributes">
                      <div *ngIf="item.type == 'text'" class="form-outline mat-form form-white">
                        <mat-form-field class="form-width" appearance="fill">

                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <!-- <label for="{{ item.name }}"><img src="http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png" /></label> -->
                          <input matInput type="text" id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" />

                        </mat-form-field>
                      </div>

                      <div *ngIf="item.type == 'email'" class="form-outline mat-form form-white">
                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <input matInput type="email" id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" />
                        </mat-form-field>
                      </div>

                      <div *ngIf="item.type == 'phone'" class="form-outline mat-form form-white">
                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <input matInput type="tel" id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="item.type == 'number'" class="form-outline mat-form form-white">
                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <input matInput type="number" id="{{ item.name }}" min="{{ item.min }}" max="{{ item.max }}"
                            [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" autocomplete="off" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="item.type == 'date'" class="form-outline mat-form form-white">

                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <input matInput type="date" id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" />
                        </mat-form-field>
                      </div>

                      <div *ngIf="item.type == 'datetime-local'" class="form-outline mat-form form-white">

                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }} </mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <input matInput type="time" id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" />

                        </mat-form-field>
                      </div>
                      <div *ngIf="item.type == 'textarea'" class="form-outline mat-form form-white">
                        <mat-form-field class="form-width" appearance="fill">
                          <mat-label class="list-header">{{ item.placeholder }}</mat-label>
                          <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                          <textarea class="list-text" matInput id="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}" autocomplete="off">
                          </textarea>
                        </mat-form-field>
                      </div>

                      <div *ngIf="item.type == 'file'" class="row form-outline form-white">

                        <div class="file-field md-form py-3">
                          <div mdbBtn size="sm" class="btn-file py-3" mdbWavesEffect>
                            <span class="text-dark ml-auto text-center">{{item.placeholder}} Ekle <span
                                *ngIf="item.required" class="required text-danger">*</span></span>
                            <input type="file" (change)="onFileChanged(item, $event)" mdbFileSelect id="{{item.name}}"
                              placeholder="{{item.placeholder}}" autocomplete="off">
                          </div>
                          <div class="file-path-wrapper">
                            <input matInput class="file-path list-text my-3" type="text"
                              placeholder="Dosyanızı yükleyin" value="{{item.fileName}}" readonly>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="item.type == 'paragraph'" class="form-outline form-white text-dark mb-4 mt-3">
                        <p class="form-text text-break text-dark">
                          {{ item.placeholder }}
                        </p>
                      </div>
                      <div *ngIf="item.type == 'autocomplete'" class="form-outline mat-form text-dark" style="border-top: 1px dashed;padding-top: 2rem;">
                        <mat-label class="text-wrap list-header text-break text-dark">{{ item.placeholder }}
                        </mat-label>
                        <mat-label *ngIf="item.required" class="required text-danger">*</mat-label>
                        <mat-form-field class="form-width pt-3" appearance="fill">
                          <mat-label class="text-wrap list-header text-break text-dark">Lütfen seçiniz
                          </mat-label>
                          <mat-select id="{{ item.name }}" class="list-text" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let v of item.values" [value]="v.value" class="list-text">{{ v.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="item.type == 'checkbox'" class="form-outline form-white text-dark mb-4">
                        <h3 class="text-wrap list-header text-break text-dark">{{ item.placeholder }}
                          <span *ngIf="item.required" class="required text-danger">*</span>
                        </h3>
                        <div *ngFor="let v of item.values">
                          <p class="cust-check-bx text-wrap mb-1 text-wrap form-text text-break text-dark">
                            <mat-checkbox [value]="v.value" name="{{ item.name }}" (click)="toggleValue(v)">
                              <mat-label class="text-wrap list-text">
                                {{ v.label }}
                              </mat-label>
                            </mat-checkbox>
                            <span class="checkmark"></span>
                          </p>
                        </div>
                      </div>

                      <!-- image checkbox list -->
                      <!-- <div *ngIf="item.type == 'img-select'" class="form-outline form-white text-dark mb-4">
                        <h3 class="text-wrap list-header text-break text-dark">{{ item.placeholder }}
                          <span *ngIf="item.required" class="required text-danger">*</span>
                        </h3>
                        <ul>
                          <li *ngFor="let img of item.values">
                            <input type="checkbox" id="{{img.value}}" />
                            <label for="{{img.value}}" class="text-wrap list-text"><img
                                src="{{img.media}}" />
                              <p class="d-block text-center">{{img.label}}</p>
                            </label>
                          </li>
                          
                        </ul>
                      </div> -->

                      <div *ngIf="item.type == 'radio'" class="form-outline form-white text-dark mb-4">
                        <h3 class="text-wrap list-header text-break text-dark">{{ item.placeholder }}
                          <span *ngIf="item.required" class="required text-danger">*</span>
                        </h3>
                        <div class="d-block">
                          <mat-radio-group name="{{ item.name }}" [(ngModel)]="item.value"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="d-block mb-3 text-wrap form-text text-break text-dark"
                              *ngFor="let v of item.values" [value]="v.value" style="padding-top: 5px">
                              <mat-label class="text-wrap list-text">
                                {{ v.label }}
                              </mat-label>
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>

                      </div>

                      <div *ngIf="item.type == 'image-select'" class="form-outline form-white text-dark mb-4">
                        <h3 class="text-wrap list-header text-break text-dark">{{ item.placeholder }}
                          <span *ngIf="item.required" class="required text-danger">*</span>
                        </h3>

                        <ul>
                          <li *ngFor="let img of item.values">
                            
                            <input type="radio" id="{{img.value}}" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}" [value]="img.value" name="{{ item.name }}"  />
                            
                            <label for="{{img.value}}" class="text-wrap list-text"><img
                                src="{{img.media}}" />
                              <p class="d-block text-center">{{img.label}}</p></label>

                          </li>
                        </ul>
                        

                      </div>
                    </div>
                    <div class="d-grid gap-2">
                      <button type="submit" class="submit-btn">
                        <span>Gönder <i class="fas fa-arrow-right"></i></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<div *ngIf="loading" class="container loading-page center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <strong class="mx-3">Yükleniyor.. </strong>
</div>