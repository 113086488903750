import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from "./anketServices/auth-interceptor.service";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnketComponent } from './anket/anket.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FileInputModule, WavesModule } from 'ng-uikit-pro-standard'
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, 
         NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SuccesPageComponent } from './succes-page/succes-page.component';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
  declarations: [
    AppComponent,
    AnketComponent,
    ErrorPageComponent,
    SuccesPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    HttpClientModule,
    MatMomentDateModule,
    MDBBootstrapModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FileInputModule,
    WavesModule,
    FileUploadModule

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
