import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, 
         HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError} from "rxjs/operators";
import { Observable, throwError } from 'rxjs';
import { AnketService } from "./anket.service";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(public anketService:AnketService, public router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // console.log("Interception In Progress"); //SECTION 1
    const token: any = localStorage.getItem('token');
    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    
 
    return next.handle(req)
        .pipe(
           catchError((error: HttpErrorResponse) => {
                //422 UNAUTHORIZED
                
                if (error && error.status === 422 && error.error.errors[0].code ) {
                    // console.log("ERROR 401 UNAUTHORIZED")
                    // console.log(error.status)
                    // console.log(status)
                    // console.log(error.error.errors[0].code);
                    this.router.navigateByUrl('/hata')
                    
                }
                if (error && error.status === 422) {
                  // console.log("ERROR 401 UNAUTHORIZED")
                  // console.log(error.status)
                  // console.log(status)
                  // console.log(error.error.errors[0].code);
                  this.router.navigateByUrl('/hata')
                  
              }
                if (error.status === 404 ) {
                  // console.log(status)
                  // console.log("ERROR 404")
                  // console.log(error.status)
                  
                  this.router.navigateByUrl('/hata')
                  
              }
              
                const err = error.error.message || error.statusText;
                return throwError(error);                    
           })
        );
  } 
}