
<div  *ngIf="successPage" class="container">
  <div class="card">
    <div class="card-1">
      <div style="border-radius:200px; height:200px; width:200px; background: #dff0c4; margin:0 auto;">
        
        <i class="fas fa-check checkmark"></i>
      </div>
        <h1 class="pt-5 pb-2">Teşekkürler!</h1>
        <hr> 
        <p class="pt-2">Cevaplarınız başarıyla gönderildi!<br/></p>
    </div>
    
    </div>
</div>
