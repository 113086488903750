import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { field, value } from '../global.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { AnketService } from "../anketServices/anket.service";
import { Observable, Subscriber } from 'rxjs';


@Component({
  selector: 'app-anket',
  templateUrl: './anket.component.html',
  styleUrls: ['./anket.component.css']
})
export class AnketComponent implements OnInit {

  success = false;

  value: value = {
    label: "",
    value: null,
    fileName:null
  };

  token: any;


  myImage:any;

  onFileChanged(item:any, $event:Event) {
    // console.log(item)

    const file=($event.target as HTMLInputElement).files![0];

    // console.log(file)
    this.convertBase64(item, file);
    // console.log($event)
    // console.log(this.myImage)
  }

  convertBase64(item:any, file:File){

    const observable= new Observable((subscriber:Subscriber<any>) =>{

  this.readFile(file,subscriber);
    });

    observable.subscribe((d)=>{
      console.log('item', item);
      for(let i = 0; i < this.formModel.attributes.length; i++) {
        if(this.formModel.attributes[i].name == item.name) {
          console.log('FOUND', this.formModel.attributes[i].fileName);
          console.log('FOUND', file.name);
          console.log('FOUND', d);
          console.log('FOUND', );

          this.formModel.attributes[i].value = d;
          this.formModel.attributes[i].fileName = file.name
          console.log('FOUND', this.formModel);
          break;
        }
      } 
    })
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error)
      subscriber.complete();
    };
  }


  loading = this.anketService.isLoading
  intro = this.anketService.intro;
  errorPage = this.anketService.errorPage
  successPage = this.anketService.successPage;


  //url='assets/form.json', remove token
  url = 'https://api.gungoren.bel.tr/v1/polling/survey/?token='

  postJson = {
    "data": {
      "attributes": {
        "form": {
          "attributes": [
            {
              "type": "text",
              "name": "string",
              "required": true,
              "value": null
            }
          ]
        }
      }
    }
  }



  reports: any = [];

  formModel: any = [];

  modelFields: Array<field> = [];
  data: any = {
  };

  constructor(private router: Router, private anketService: AnketService,
    private http: HttpClient, private activatedRoute: ActivatedRoute) { }




    radioSel:any;
    radioSelected:string='';
    radioSelectedString:string='';

    getSelecteditem(){
      this.radioSel = this.formModel.attributes.find((Item:any) => Item.value === this.radioSelected);
      this.radioSelectedString = JSON.stringify(this.radioSel);
      
    }

    onItemChange(){
      this.getSelecteditem();
    }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });


    // this.activatedRoute.queryParams.subscribe(queryParams => {
    //   const token = queryParams['token'];
    //   // Call your Backend API with the token after this

    //   console.log(token)
    // });
    this.loading = true
    this.reports = this.data.attributes;

    this.getForm();

  }

  getForm() {
    this.anketService.sendGetRequest(this.token).subscribe((data: any) => {
      this.formModel = data.data.attributes;
      this.loading = false
      this.intro = true
    })

  }



  // setTimeout(function(){
  //               window.location.href = 'https://www.tutorialspoint.com/javascript/';
  //            }, 5000);
  toggleValue(item: any) {
    item.selected = !item.selected;
  }

  submit(data: NgForm): any {
    let valid = true;

    let validationArray = JSON.parse(JSON.stringify(this.formModel.attributes));
    validationArray.reverse().forEach((field: any): any => {
      if (field.required && !field.value && field.type != 'checkbox') {
        swal.fire('Hata Oluştu', 'Bu alan boş bırakılmaz! ' + field.placeholder, 'error');
        valid = false;
        return false;
      }
      if (field.required && field.regex && field.min && field.max) {
        let regex = new RegExp(field.regex);
        if (regex.test(field.value) == false) {
          swal.fire('Hata Oluştu', field.errorText, 'error');
          valid = false;
          return false;
        }
      }
      if (field.required && field.type == 'checkbox') {
        if (field.values.filter((r: any) => r.selected).length == 0) {
          swal.fire('Hata Oluştu', 'Bu alan boş bırakılmaz! ' + field.placeholder, 'error');
          valid = false;
          return false;
        }

      }
      if (field.type == 'number' && field.value < field.min || field.value > field.max) {
        swal.fire('Hata Oluştu', `Bu alan boş bırakılmaz! Minimum ${field.min} ve Maksimum ${field.max} ${field.placeholder}`, 'error');
        valid = false;
        return false;

      }
    });
    if (!valid) {
      return false;
    }
    this.postJson.data.attributes.form = this.formModel;
    //post method add this.token
    this.http.post(this.url+this.token, this.postJson).subscribe(data => {
      this.success = true;
      this.router.navigateByUrl('/basarili')
      this.intro = false
      this.loading = false
      setTimeout(() => {
        const redirectUrl = "https://www.gungoren.bel.tr/";
        window.location.href = redirectUrl;
        console.log(window.location.href)
      }, 3000);

      
    });

  }

}
